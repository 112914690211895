import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { CircleChecked, Page } from '@lib/components'

export const FAQs: FC = () => {
  const scrollToSection = (id: string): void => {
    document.getElementById(id)?.scrollIntoView()
  }

  return <Page skinny className="px-10 my-16 font-medium">
    <h1 className="my-8 text-2xl font-semibold md:text-4xl text-mtd-blue">FAQs</h1>

    <section>
      <p>Everything you need to know about MyTenDays. Jump to:</p>
      <div className="mt-4 font-semibold text-mtd-blue-800 md:flex">
        <ul className="flex-1">
          <li className="mt-2 underline cursor-pointer md:mt-0" onClick={() => { scrollToSection('about') }}>About</li>
          <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('donation') }}>Donation process</li>
          <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('postDonation') }}>Post donation</li>
        </ul>
        <ul className="flex-1">
          <li className="mt-2 underline cursor-pointer md:mt-0" onClick={() => { scrollToSection('giftAidTax') }}>GiftAid (UK) &#38; tax-deductibility (USA)</li>
          <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('security') }}>Security</li>
          <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('moreInfo') }}>More Information</li>
        </ul>
      </div>

    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="about">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">About MyTenDays</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is MyTenDays?</h3>
      <p className="mt-4">My Ten Days is an online platform that automates your charitable donations during the first ten days of Dhul Hijjah. This means you will never miss the reward of giving on these blessed days again. With your Sadaqah and Zakat taken care of, you’ll never miss giving on the 10 best days of Dhul Hijjah!</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Is MyTenDays a registered charity?</h3>
      <p className="mt-4">No. We are a limited company that builds culture changing products for charities.</p>

    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="donation">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">The donation process</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Do we deduct anything from your donation?</h3>
      <p className="mt-4">No. We take nothing from your donation. 100% of your donation goes to the charity.</p>
      <p className="mt-4">On your checkout, you can choose to cover our 3rd party fees, which include card processing fees. If you choose to not cover those fees, they will be deducted from your donation.
      </p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is the Transaction Fee?</h3>
      <p className="mt-4">This is our best estimation of what the 3rd party card processing and fees will be for your country. Paying this fee is optional and it can be removed before you check out. Please note that if you do remove the transaction fee, this will instead be deducted from your total donation, reducing the amount that the charity receives.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is the MyTenDays Tip?</h3>
      <p className="mt-4">The tip is an optional payment which goes directly to the ongoing running and development of the MyTenDays platform. This can be decreased, increased or removed completely at your discretion. Please note that the tip is taken with your first night's payment, in full and not spread across the ten nights like your donation.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How can I spilt my donations?</h3>
      <p className="mt-4">There are 2 ways to split your donations:</p>
      <ul>
        <li className="flex items-start mt-4">
          <CircleChecked className="mt-1 mr-4 text-mtd-blue" />
          <div className="flex-1">
            Equally across all 10 days
          </div>
        </li>
        <li className="flex items-start mt-2">
          <CircleChecked className="mt-1 mr-4 text-mtd-blue" />
          <div className="flex-1">
            More on the day of Arafah
          </div>
        </li>
      </ul>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How do you calculate the daily splitting of donations?</h3>
      <p className="mt-4">We split your donations however you instruct us to do on your schedule.</p>
      <p className="mt-4">Note, because we can’t split pennies/cents into fractions, you’ll sometimes see a small rounding up or down on the last night of your schedule to ensure the full donation is taken. This is normal. But if you see anything strange in your schedule,
        just contact our Donor Care Team on <a
          className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a>.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What time do you process the daily donations?</h3>
      <p className="mt-4">We process each daily donation individually, at approximately midday in your locality.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What happens if my donation is rejected?</h3>
      <p className="mt-4">We will send you an email notifying you that your payment was rejected. You can then login to your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> to try the payment again.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I’ve caught this late. There are only 5 days left. Can I still donate?</h3>
      <p className="mt-4">Yes, you can schedule donations up until 11am in your local time on the 9th day of Dhul Hijjah.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Can I automate my donations so that next year I don’t need to set up another donation schedule?</h3>
      <p className="mt-4">Yes, on check out you can opt to automate your donations each year. We will copy this year's donation to the same charity and split it over the first ten days of Dhul Hijjah, just like you’ve chosen this year. You can amend or cancel this at any time, and before every Dhul Hijjah we’ll send you a reminder with your options.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How do I know my money is going to the charity?</h3>
      <p className="mt-4">MyTenDays is registered under the Fundraising Regulator. We are wholly transparent and welcome donors to query with charities if their donation was ultimately received in full. We also issue an Annual Report each year. If you would like us to connect you directly with our contact at your chosen charity, we are happy to do so. Please contact us.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">My bank statement says that donations are not coming out on the right days. What is happening?</h3>
      <p className="mt-4">Please note, for non-digital banks, it may take some time/days for the transaction to appear on your bank statement. Some banks show transactions instantly while others may take up to 5 working days to appear. Even though donations are processed nightly,
        it may be that your bank waits 5 days to populate them, and may even show all on the same day, despite the transaction happening on different days. E.g. you may pay for a Starbucks coffee on a Tuesday, but the bank only shows it on your statement as a payment on Friday. Rest assured however – we took your money on the correct day at the correct time.!</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Where does my donation go if I select 'Spread Donations Across Charities with One Click'?</h3>
      <p className="mt-4">By selecting this option, we hope to raise the bar for all charities across the board by redistributing donations to verified charities on our platform that have continuously displayed the highest level of clarity, transparency, accountability, and impact. You will receive feedback on how the funds have been allocated in our annual report.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to do a second donation on behalf of someone else (e.g. for my mum)</h3>
      <p className="mt-4">That’s absolutely fine. Make the intention for the person you want to donate for and start the donation process again.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Can I donate if I live outside of the countries listed?</h3>
      <p className="mt-4">Yes. Though note that the transaction will be calculated in the native currency of the charity. So for UK charities all donations will be GBP, for US charities they will be USD, and so on…</p>
    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="postDonation">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">Post donation</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How can I track if my donations have been processed?</h3>
      <p className="mt-4">You have your own user dashboard. Simply click the <Link className="underline text-mtd-blue" to="account/schedules">My Profile</Link> link on the homepage
        and enter your email address. You’ll receive an email with your single-use passcode. Enter the code to see the payments that are scheduled, which ones have been made, if
        any failed, and what precise time after sunset your payments were taken.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to edit my account</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> and any options that can be edited will be available. Where you cannot edit a field, contact our Donor Care Team on  <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a> who will be able to assist you with this.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to edit my donation</h3>
      <p className="mt-4">Once a schedule is created, you are unable to make changes to it. If you wish to make any changes, you must cancel your schedule and create a new one in your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a></p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to cancel my donation</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> where you will be able to cancel your schedule.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to reactivate my donation</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> where you will be able to reactivate your schedule. </p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to cancel the auto opt in for an automation schedule for next year</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> where you will be able to cancel the opt-in for auto enrolment next year.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to activate/reactivate the auto opt in for an automation schedule for next year</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> where you will be able to activate/reactivate the opt-in for auto enrolment next year.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I want to refund my donation</h3>
      <p className="mt-4">Please contact our Donor Care Team on <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a> who will be able to assist you with this. </p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">I didn’t receive a confirmation email or nightly donation receipt</h3>
      <p className="mt-4">Sometimes, these emails can be found in your junk / spam mail. Please check these. If there is still no confirmation email, please contact our Donor Care Team on <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a> who will be able to assist you with this.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is my password?</h3>
      <p className="mt-4">MyTendays does not require you to set your own password. Instead, a one time password for your account is issued each time you want to log in. Click <Link className="underline text-mtd-blue" to="account/schedules">My Profile</Link>
        on the home page and enter the email address you used to register last year. A passcode will be sent to you. Please note the password can only be used once and does expire if not used within 15 minutes.</p>
    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="giftAidTax">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">GiftAid and tax-deductibility</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Do donations to my charity of choice qualify for GiftAid in the UK?</h3>
      <p className="mt-4">Yes. Donations to MyTenDays qualify for GiftAid in the UK. Voluntary contributions to MyTenDays (clearly marked as separate from your charity donation) do not qualify for GiftAid as we are a limited company.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Are donations to my charity of choice tax-deductible in the USA, Canada and Australia?</h3>
      <p className="mt-4">Yes. Donations to our charity partners are tax-deductible in the USA, Canada and Australia.
        Voluntary contributions to MyTenDays (clearly marked as separate from your charity donation) do not qualify for tax-deductions as we are a limited company.
      </p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Will I receive a Tax Receipt?</h3>
      <p className="mt-4">Yes. You should receive a tax receipt before the end of the tax year and you should not need to take any further action. However, if you need one sooner, please contact our Donor Care Team on <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a>
        who will issue this for you. Please note that the receipt is issued by our DAF partner, relevant to your country as your donation was essentially received by them and onward gifted to your chosen charity.</p>
    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="security">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">Security</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Is my payment secure?</h3>
      <p className="mt-4">Yes. We take security seriously and meet the security standards of the EU PSD2 directives. All our payments are processed through our payment provider, Stripe. We do not store any card details on our database.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Is my data safe?</h3>
      <p className="mt-4">Yes. We take your data seriously. Please see our <Link className="underline text-mtd-blue" to="/privacy-policy">privacy policy</Link>. We do not share your data with any third party without your permission unless it is required to access the service. We will only contact you or pass on your contact details to your charity of choice if you explicitly ask us to.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Can I remain anonymous?</h3>
      <p className="mt-4">All donations are anonymous to the public. You need to provide your name to validate your payment card, opt-in to marketing, and to request GiftAid or a tax receipt. Charities will only receive your full name if you opt-in to share your details.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Will you pass on my data to charities?</h3>
      <p className="mt-4">Only if you tell us to (tick the opt-in box). We’ll never pass on your data without your consent. There is an exception for a handful of charities that pass our enhanced vetting. These are charities who, for legitimate reasons, need a record of all individuals who have donated to their charity. These charities must pass our due diligence and sign our data sharing agreement, which means they can never contact you, or do anything with this data, other than the admin activities stipulated in our sharing agreement.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Will you sell or trade my data?</h3>
      <p className="mt-4">Never. We are fully GDPR compliant. We’ll never do anything to annoy you. Please see our Privacy Policy <Link className="underline text-mtd-blue"
        to="/privacy-policy">here</Link>.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Will you ever mishandle my data?</h3>
      <p className="mt-4">Never. We are fully GDPR compliant. We’ll never do anything to annoy you. Please see our Privacy Policy <Link className="underline text-mtd-blue"
        to="/privacy-policy">here</Link>.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Transparency and Accountability</h3>
      <p className="mt-4">Where can I read your terms and conditions?<br /><Link className="underline text-mtd-blue" to="/terms-and-conditions">Here</Link></p>

      <p className="mt-4">Where can I read your privacy policy?<br /><Link className="underline text-mtd-blue" to="/privacy-policy">Here</Link></p>
    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="givematch">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">Givematch x MyTenDays</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is Givematch?</h3>
      <p className="mt-4">Givematch is an online platform where donors have their donations matched based on their referrals. Donors share their favourite campaigns with their friends - when two of their friends donate, they have their (original) donation matched.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">What is Givematch x MyTenDays?</h3>
      <p className="mt-4">We have partnered with Givematch so donors can get their MyTenDays donations matched. On the MyTenDays checkout page you will have the option to opt-in to Givematch. You will be emailed a unique link to share and if 2 friends donate via this link your donation will be doubled (usually up to £200 but this does vary by country). There are limited matched funds available so this opportunity is only available while there are funds in the match pot.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">If I share my link with 2 people do they have to donate the same amount as me?</h3>
        <p className="mt-4">Those who donate through your link can donate any amount to their chosen charity to be eligible. It does not need to be the same as your donation. If you successfully refer two friends to donate and earn a matched donation, it will usually go to the charity you made your original donation to.
        <br/>
        However, in exceptional circumstances and at their own discretion, MyTenNights and Givematch also reserve the right to allocate the matched funding to other worthy charitable causes.
        </p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">If I share my link with 2 people do they have to donate to the same charity as me?</h3>
      <p className="mt-4">Those who donate through your link can donate to any charity of their choice. It does not need to be the same charity as you.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How do I find out if my donation got doubled?</h3>
      <p className="mt-4">Log into your <a className="underline text-mtd-blue" href="https://www.mytendays.com/account/schedules" target="_blank"
        rel="noopener noreferrer">Donor Dashboard</a> where you will be able to see the amount that is eligible for matching, how many people have used your link to donate, and you can also access your share code link again. Once you have 2 friends donate using your link, the donation amount will be matched and onward gifted to your chosen charity.</p>

      <h3 className="mt-8 text-lg font-bold">How does MyTenDays sustain itself?</h3>
      <p className="mt-4">We rely on voluntary tips from our users to sustain ourselves. We do not take a cut of public donations, and nor do we price any services as a percentage of donations raised. You can be reassured that 100% of your donation reaches the charity, minus any fees deducted by third parties outside of our control, such as card processing fees.</p>
      <p className="mt-4">Holding ourselves to the highest standard of transparency, your donation is never handled by us, and nor do we ever have any control over it. Your donation moves directly from your bank account into the trusted hands of an independent 3rd party donation escrow, called a Donor Advised Fund (DAF). DAFs work as a self-imposed regulator for us to reassure the public that all of your money is handled responsibly by an independent body and your chosen charity. We facilitate the software to empower charities whilst staying entirely outside of the money flow of your donation.</p>
      <p className='mt-4'>In the vein of transparency, we welcome all donors, charities and corporate partners to liaise directly with our chosen DAF partners for any further queries.</p>
      <p className="mt-4">The details for our DAF partners are:</p>
      <ul>
        <li className="flex flex-col items-start mt-4">
          <p>United Kingdom:</p>
          <p>Mercy Mission UK, registered charitable organization number 1122922</p>
        </li>
        <li className="flex flex-col items-start mt-4">
          <p>Canada:</p>
          <p>Myriad Canada, registered charitable organization number 76978 4893 RR0001</p>
        </li>
        <li className="flex flex-col items-start mt-4">
          <p>United States of America:</p>
          <p>Myriad USA, registered charitable organization number 58-2277856</p>
        </li>
        <li className="flex flex-col items-start mt-4">
          <p>Australia:</p>
          <p>Myriad Australia, registered charitable organization number ABN 20 640 318 636</p>
        </li>
      </ul>
    </section>

    <div className="flex-none bg-gradient-to-r from-white via-mtd-gray-200 to-white my-8 h-0.5"></div>

    <section id="moreInfo">
      <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtd-blue">More Information</h2>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Are you independent?</h3>
      <p className="mt-4">Yes</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">Who runs MyTenDays</h3>
      <p className="mt-4">MyTenDays is run by Shamaazi Ltd. We are a group of tech entrepreneurs from the UK that have decided to apply the power of technology to giving. The result is MyTenDays. We believe we can transform the world into a better place much faster by applying technology to the charitable sector.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How long has MyTenDays been around?</h3>
      <p className="mt-4">Since 2017! In total, we have raised tens of millions of pounds for charities all over the world, from over 200,000 donors.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How can I support MyTenDays?</h3>
      <p className="mt-4">You can add a voluntary donation at the end of the donation process.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How can I volunteer or work at MyTenDays?</h3>
      <p className="mt-4">Any opportunities will be advertised on our Careers Page <a className="underline text-mtd-blue" href="https://shamaazi.com/careers/" target="_blank"
        rel="noopener noreferrer">https://shamaazi.com/careers</a></p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How can I become a charity partner next year?</h3>
      <p className="mt-4">Please contact us on <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a> and we will direct you to the appropriate team.</p>

      <h3 className="mt-8 text-lg md:text-xl text-mtd-blue">How do I access donor support</h3>
      <p className="mt-4">You can contact our Donor Care Team in the following ways:</p>
      <ul>
        <li className="flex items-start mt-2">
          <CircleChecked className="mt-1 mr-4 text-mtd-blue" />
          <div className="flex-1">
            Email - <a className="underline text-mtd-blue" href="mailto:contact@mytendays.com">contact@mytendays.com</a>
          </div>
        </li>
        <li className="flex items-start mt-2">
          <CircleChecked className="mt-1 mr-4 text-mtd-blue" />
          <div className="flex-1">
            Telephone - (+44) 020 4541 6388
          </div>
        </li>
      </ul>
    </section>
  </Page>
}
